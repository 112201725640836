<template>
  <el-dialog
    width="60%"
    visible
    :show-close="false"
    append-to-body
    custom-class="rounded bank-payment-modal"
    @close="close"
  >
    <template #title>
      <div class="p-4 border-bottom">
        <div class="d-flex justify-content-between align-items-center mb-2">
          <h2>{{ `${translate('title')} - ${currentTenant.name}` }}</h2>
          <div class="d-flex align-items-center">
            <el-dropdown trigger="click" @command="handleAction">
              <div class="text-typography-primary actions-btn" :class="$direction === 'ltr' ? 'me-1' : 'ms-1'">
                <KebabIcon width="24" height="24" />
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="ACTION.DOWNLOAD_FILE">
                  <div class="d-flex align-items-center">
                    <DownloadIcon width="24" height="24" :class="$direction === 'ltr' ? 'me-1' : 'ms-1'" />
                    {{ translate('actions.downloadFile') }}
                  </div>
                </el-dropdown-item>
                <el-dropdown-item
                  :divided="true"
                  :command="ACTION.ADD_UNIFORM_INSTRUCTION"
                  :class="$direction === 'ltr' ? 'ms-1' : 'me-1'"
                >
                  <div :class="$direction === 'ltr' ? 'ms-5' : 'me-5'">
                    {{ translate('actions.addUniformInstruction') }}
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <Button type="text" class="p-0 text-typography-primary actions-btn" @click="close">
              <CloseIcon width="24" height="24" />
            </Button>
          </div>
        </div>
        <p class="text-typography-primary">{{ bankAccountDetails }}</p>
      </div>
    </template>
    <div v-loading="loading" class="p-4 d-flex flex-column">
      <el-form ref="form" class="mb-4" :model="formModel" :show-message="false">
        <Table border rounded :columns="columns" :data="tableData" show-index>
          <template
            #cell-amountToPay="{
              rowData: {
                amountToPay: { amount, billingsCount },
              },
            }"
          >
            <p>{{ formatMoney(amount) }}</p>
            <small class="d-block text-muted text-nowrap">
              {{ translate('table.invoiceCount', { count: billingsCount }) }}
            </small>
          </template>
          <template #cell-instructionNum="{ rowData: { supplierId } }">
            <el-form-item required :prop="`instructionNumbers.${supplierId}`">
              <el-input v-model="formModel.instructionNumbers[supplierId]" size="small" />
            </el-form-item>
          </template>
          <template
            #cell-supplierBankAccount="{
              rowData: {
                supplierBankAccount: { account, bank },
              },
            }"
          >
            <p>{{ account }}</p>
            <small class="d-block text-muted text-nowrap">
              {{ bank }}
            </small>
          </template>
        </Table>
      </el-form>
      <div class="d-flex flex-column align-items-end align-self-end pb-4">
        <p>{{ translate('footer.total') }}</p>
        <h2 class="text-typography-primary">
          {{ formatMoney(totalPaymentsAmount) }}
        </h2>
      </div>
    </div>
    <template #footer>
      <div class="pb-4 px-4">
        <Button type="secondary" @click="close">
          {{ $t('commons.cancel') }}
        </Button>
        <Button @click="submit">
          {{ $t('commons.save') }}
        </Button>
      </div>
    </template>
    <UniformInstructionNumberModal
      v-if="isUniformInstructionNumberModalOpen"
      @close="isUniformInstructionNumberModalOpen = false"
      @instructionNumberSubmitted="handleUniformInstructionNumber"
    />
  </el-dialog>
</template>

<script>
import { ref, getCurrentInstance, computed, inject } from 'vue';
import { groupBy, map, pluck } from 'ramda';
import { DateTime } from 'luxon';
import { saveAs } from 'file-saver';
import axios from 'axios';

import { Button, Table } from '@/modules/core';
import { KebabIcon, CloseIcon, DownloadIcon } from '@/assets/icons';
import { useTenancy } from '@/modules/auth';
import { useCurrency } from '@/modules/core/compositions/money-currency';

import UniformInstructionNumberModal from './UniformInstructionNumberModal';
import { formatDate } from '../../../../tools/formatters';
import {
  useSupportedBanks,
  useBankAccounts,
  usePaymentsInProgress,
  PAYMENT_PAGE_CONTEXT,
} from '../../../../compositions';
import { CREATE_FEEDING_FILE_MUTATION } from '../compositions/useCreateFeedingFile';

const TABLE_HEADER = {
  SUPPLIER: 'supplier',
  PAYMENT_DATE: 'paymentDate',
  BENEFICIARY_NAME: 'beneficiaryName',
  SUPPLIER_BANK_ACCOUNT: 'supplierBankAccount',
  AMOUNT_TO_PAY: 'amountToPay',
  INSTRUCTION_NUM: 'instructionNum',
};

const ACTION = {
  DOWNLOAD_FILE: 'downloadFile',
  ADD_UNIFORM_INSTRUCTION: 'addUniformInstruction',
};

export default {
  components: { Button, Table, KebabIcon, CloseIcon, DownloadIcon, UniformInstructionNumberModal },
  props: {
    payments: { type: Array, default: () => [] },
    suppliers: { type: Array, default: () => [] },
  },
  setup(props) {
    const { currentTenant } = useTenancy();
    const { getPaymentByTransactionId } = inject(PAYMENT_PAGE_CONTEXT);

    const root = getCurrentInstance().proxy;
    const tenantId = ref(root.$route.params.tenantId);
    const { patchTransaction, patchTransactionLoading } = usePaymentsInProgress(
      computed(() => ({ businessId: tenantId.value, completed: false })),
      getPaymentByTransactionId
    );

    const { bankAccounts: currentTenantBankAccounts } = useBankAccounts(computed(() => currentTenant.value?.id));

    const currentTenantBankAccount = computed(() => currentTenantBankAccounts.value[0]);

    const countryCode = computed(() => currentTenant.value?.countryCode);
    const { supportedBanks } = useSupportedBanks(countryCode);

    const { formatToCurrency } = useCurrency();

    const formatMoney = (value) => {
      if (typeof value === 'number' && !Number.isNaN(value)) {
        const number = Number(value.toFixed(2));
        const options = Number.isInteger(number) ? { maximumFractionDigits: 0 } : {};
        return formatToCurrency(value, options);
      }
      return '-';
    };

    return {
      countryCode,
      supportedBanks,
      formModel: ref({
        instructionNumbers: props.payments.reduce((acc, currPayment) => ({ ...acc, [currPayment.supplierId]: '' }), {}),
      }),
      isUniformInstructionNumberModalOpen: ref(false),
      ACTION,
      formatMoney,
      currentTenant,
      patchTransaction,
      loading: patchTransactionLoading,
      currentTenantBankAccount,
      CREATE_FEEDING_FILE_MUTATION,
    };
  },
  computed: {
    columns() {
      return [
        {
          header: this.translate('table.headers.supplier'),
          key: TABLE_HEADER.SUPPLIER,
        },
        {
          header: this.translate('table.headers.paymentDate'),
          key: TABLE_HEADER.PAYMENT_DATE,
        },
        {
          header: this.translate('table.headers.beneficiary'),
          key: TABLE_HEADER.BENEFICIARY_NAME,
        },
        {
          header: this.translate('table.headers.supplierBankAccount'),
          key: TABLE_HEADER.SUPPLIER_BANK_ACCOUNT,
        },
        {
          header: this.translate('table.headers.amountToPay'),
          key: TABLE_HEADER.AMOUNT_TO_PAY,
        },
        {
          header: this.translate('table.headers.instructionNum'),
          key: TABLE_HEADER.INSTRUCTION_NUM,
          width: '136px',
        },
      ];
    },
    bankAccountDetails() {
      const bankAccount = this.currentTenantBankAccount;
      if (!bankAccount) return this.translate('titleLabels.noAccount');
      return this.countryCode === 'IL'
        ? `${this.supportedBanks[bankAccount.bankNumber]}, ${this.translate('titleLabels.branch')} ${
            bankAccount.branchNumber
          }, ${this.translate('titleLabels.account')} ${bankAccount.accountNumber}`
        : `${bankAccount.routingNumber}, ${this.translate('titleLabels.account')} ${bankAccount.accountNumber}`;
    },
    paymentsGroupedBySupplier() {
      return groupBy((payment) => {
        const { bankNumber, branchNumber, accountNumber } = payment.transactions[0].destinationBankAccount;
        return `${payment.supplierId}_${bankNumber}-${branchNumber}-${accountNumber}`;
      })(this.payments);
    },
    totalPaymentsAmount() {
      return this.payments.reduce((sum, p) => sum + p.amount, 0);
    },
    tableData() {
      return Object.entries(this.paymentsGroupedBySupplier).map(([supplierIdAndBankDetails, payments]) => {
        const supplierId = supplierIdAndBankDetails.split('_')[0];
        const supplier = this.suppliers.find((supplier) => supplier.id === supplierId);
        const totalAmount = payments.reduce((sum, p) => sum + p.amount, 0);
        const billingsCount = payments.reduce((count, p) => count + p.billingLinks.length, 0);
        const bankAccount = payments[0].transactions[0].destinationBankAccount;
        return {
          [TABLE_HEADER.SUPPLIER]: supplier?.name ?? '-',
          [TABLE_HEADER.BENEFICIARY_NAME]: bankAccount.accountName,
          [TABLE_HEADER.AMOUNT_TO_PAY]: { amount: totalAmount, billingsCount },
          [TABLE_HEADER.SUPPLIER_BANK_ACCOUNT]: {
            account: bankAccount.accountNumber,
            bank: this.countryCode === 'IL' ? this.supportedBanks[bankAccount.bankNumber] : bankAccount.routingNumber,
          },
          [TABLE_HEADER.PAYMENT_DATE]: formatDate(payments[0].date),
          supplierId,
        };
      });
    },
  },
  methods: {
    handleUniformInstructionNumber(instructionNumber) {
      this.isUniformInstructionNumberModalOpen = false;
      this.formModel.instructionNumbers = map(() => instructionNumber, this.formModel.instructionNumbers);
    },
    handleAction(command) {
      switch (command) {
        case ACTION.DOWNLOAD_FILE:
          this.downloadFile();
          break;
        case ACTION.ADD_UNIFORM_INSTRUCTION:
          this.isUniformInstructionNumberModalOpen = true;
          break;
        default:
          break;
      }
    },
    async downloadFile() {
      try {
        const { data } = await this.$apollo.mutate({
          mutation: CREATE_FEEDING_FILE_MUTATION,
          variables: { ids: pluck('id', this.payments) },
        });
        await this.saveFile(data);
      } catch (error) {
        this.$message.error(this.$t('payment.bankPaymentDocumentation.CannotDownloadFeedingFile'));
      }
    },
    async saveFile(data) {
      const fileName = `${this.translate('filename', {
        customer: this.currentTenant.name,
        date: DateTime.fromJSDate(new Date()).toISODate(),
        bankAccount: this.getBankAccount(),
      })}.msv`;

      axios.get(data.createFeedingFile, { responseType: 'blob' }).then((response) => {
        saveAs(response.data, fileName);
      });
    },
    getBankAccount() {
      const getShortBankDetails = ({ bankNumber, branchNumber, accountNumber }) =>
        `${bankNumber}-${branchNumber}-${accountNumber}`;
      return this.currentTenantBankAccount ? getShortBankDetails(this.currentTenantBankAccount) : '';
    },
    translate(key, args) {
      const fullKey = `payment.bankPaymentDocumentation.${key}`;
      return args ? this.$t(fullKey, args) : this.$t(fullKey);
    },
    close() {
      this.$confirm(
        this.$t('commons.cancellation.cancellationMessage'),
        this.$t('commons.cancellation.processCancellation'),
        {
          showClose: false,
          confirmButtonText: this.$t('commons.cancellation.cancelProcess'),
          confirmButtonClass: 'el-button--danger',
          cancelButtonText: this.$t('commons.cancellation.continueProcess'),
          cancelButtonClass: 'el-button--secondary',
        }
      )
        .then(() => {
          this.$emit('close');
        })
        .catch(() => null);
    },
    submit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          Promise.all(
            this.payments.map((paymentData) => {
              const relevantTransaction = paymentData.transactions.find(
                (transaction) => transaction.paymentMethod === 'bankTransfer'
              );
              if (!relevantTransaction) return null;

              const instructionNumber = this.formModel.instructionNumbers[paymentData.supplierId];
              if (!instructionNumber) return null;

              return this.patchTransaction({
                paymentId: paymentData.id,
                transactionId: relevantTransaction.id,
                transactionPatchParams: { instructionNumber },
              });
            })
          )
            .then(() => {
              this.$message.success(this.translate('transactionPatchMessages.success'));
              this.$emit('submit');
            })
            .catch(() => this.$message.error(this.translate('transactionPatchMessages.error')));
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

::v-deep .bank-payment-modal {
  .el-dialog__header,
  .el-dialog__body,
  .el-dialog__footer {
    padding: 0;
    color: $typography-primary;
  }
}

::v-deep .el-form-item {
  margin-bottom: 0;
}

.actions-btn {
  cursor: pointer;
  &:hover {
    background: $secondary;
  }
  &.active {
    visibility: visible;
  }
}

h2 {
  font-size: 1.3rem;
}
</style>
