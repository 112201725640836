<template>
  <el-dialog
    width="20.5%"
    visible
    :show-close="false"
    append-to-body
    custom-class="rounded uniform-instruction-number-modal"
    @close="close"
  >
    <template #title>
      <div class="d-flex justify-content-between align-items-center p-4 border-bottom">
        <h2>{{ translate('title') }}</h2>
        <Button type="text" class="p-0 text-typography-primary actions-btn" @click="close">
          <CloseIcon width="24" height="24" />
        </Button>
      </div>
    </template>
    <div class="py-2 px-4">
      <p>
        {{ translate('subtitle') }}
      </p>
      <el-form ref="form" :model="formModel" :show-message="false">
        <el-form-item required prop="instructionNumber">
          <el-input v-model="formModel.instructionNumber" size="small" />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <div class="pb-4 px-4">
        <Button type="secondary" @click="close">
          {{ $t('commons.cancel') }}
        </Button>
        <Button @click="submit">
          {{ $t('commons.apply') }}
        </Button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { Button } from '@/modules/core';
import { CloseIcon } from '@/assets/icons';

export default {
  components: {
    Button,
    CloseIcon,
  },
  data() {
    return {
      formModel: {
        instructionNumber: null,
      },
    };
  },
  methods: {
    translate(key) {
      return this.$t(`payment.bankPaymentDocumentation.uniformInstructionModal.${key}`);
    },
    close() {
      this.$emit('close');
    },
    submit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.$emit('instructionNumberSubmitted', this.formModel.instructionNumber);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

::v-deep .uniform-instruction-number-modal {
  .el-dialog__header,
  .el-dialog__body,
  .el-dialog__footer {
    padding: 0;
    color: $typography-primary;
  }
}

::v-deep .el-form-item {
  margin-bottom: 0;
}

.actions-btn {
  cursor: pointer;
  &:hover {
    background: $secondary;
  }
  &.active {
    visibility: visible;
  }
}

h2 {
  font-size: 1.3rem;
}
</style>
